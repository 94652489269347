/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header"
import Footer from "../footer"
import "../styles/layout.scss"

const Layout = ({ french, children }) => {
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const handleScroll = () => {
    if (ref.current) {
      if (window.scrollY === 0) {
        setSticky(false)
      } else if (ref.current.getBoundingClientRect().top <= 0) {
        setSticky(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])
  const data = useStaticQuery(graphql`
    query SiteTitleQueryFr {
      site {
        siteMetadata {
          title
        }
      }
      prismic {
        allJobs(lang: "fr-ca") {
          edges {
            node {
              title
            }
          }
          totalCount
        }
      }
    }
  `)

  let allJobs = data.prismic.allJobs.edges
  return (
    <>
      <div className={`sticky-wrapper${isSticky ? " sticky" : ""}`} ref={ref}>
        <Header
          french={french}
          allJobs={allJobs}
          siteTitle={data.site.siteMetadata.title}
        />
      </div>
      <div className={`content-wrapper${isSticky ? " sticky-body" : ""}`}>
        <main>{children}</main>
      </div>
      <Footer french={french}></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
